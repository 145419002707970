import {Link} from '@ezcater/tapas';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import logoVertical from '@/assets/images/logo-vertical-white.png';
import useTracking from '@/hooks/useTracking';

const AppDownloadCtaMobile: React.FC = () => {
  const {t} = useTranslation('common');
  const {trackClick} = useTracking();

  return (
    <div className="mb-8 flex flex-col gap-4 rounded-md border border-peppercorn-200 bg-white p-4">
      <div className="flex items-center gap-4">
        <Image
          className="rounded-md bg-ezgreen-300 p-2"
          alt=""
          src={logoVertical.src}
          width="48"
          height="48"
        />
        <div className="flex flex-col items-start gap-1">
          <h4 className="font-bold text-peppercorn-800">
            {t('components.Footer.appDownloadCta.titleMobile')}
          </h4>
          <p className="text-left text-xs text-peppercorn-800">
            {t('components.Footer.appDownloadCta.descriptionMobile1')} &mdash;{' '}
            {t('components.Footer.appDownloadCta.descriptionMobile2')}
          </p>
        </div>
      </div>
      <Link
        href="https://ezcater.onelink.me/T4QA/2im2xgzr"
        onClick={() => trackClick('footer-app-download-mobile')}
        className="w-full rounded-lg bg-ezgreen-400 py-2.5 text-base font-bold text-white hover:bg-ezgreen-500 hover:text-white hover:no-underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('components.Footer.appDownloadCta.openApp')}
      </Link>
    </div>
  );
};

export default AppDownloadCtaMobile;
