import type React from 'react';

import useOrderingExperience from '@/hooks/useOrderingExperience';

const EzOExperience: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {isEzOrdering} = useOrderingExperience();

  return isEzOrdering ? <>{children}</> : null;
};

export default EzOExperience;
