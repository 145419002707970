const useEzCaterCompanyInfo = () =>
  ({
    __typename: 'EzCaterCompanyInfo',
    address: {
      __typename: 'ProposedAddress',
      name: 'ezCater, Inc.',
      street: '40 Water Street',
      street2: '5th Floor',
      city: 'Boston',
      state: 'MA',
      zip: '02109',
    },
    supportPhoneNumber: '1-800-488-1803',
    supportFaxNumber: '1-866-847-1510',
  }) as const;

export default useEzCaterCompanyInfo;
