import {type FC, Fragment} from 'react';
import {type Translate} from 'next-translate';
import useTranslation from 'next-translate/useTranslation';

import useTracking from '@/hooks/useTracking';
import Divider from './Divider';

export type TrackedLinksProps = {
  as?: 'li' | 'span';
  className?: string;
  links: {key: string; path: string}[];
  useDivider?: boolean;
};

const routeLabel = (key: string, t: Translate) => t(`components.Footer.pathNames.${key}`);

const TrackedLinks: FC<TrackedLinksProps> = ({
  as: Tag = 'li',
  className,
  links,
  useDivider = false,
}) => {
  const {t} = useTranslation('common');
  const tracker = useTracking();

  return (
    <>
      {links.map((link, index) => (
        <Fragment key={link.key}>
          <Tag>
            <a
              className={className}
              href={link.path}
              onClick={() => tracker.trackClick(`footer-${link.path}`)}
            >
              {routeLabel(link.key, t)}
            </a>
          </Tag>
          {index < links.length - 1 && useDivider && <Divider />}
        </Fragment>
      ))}
    </>
  );
};

export default TrackedLinks;
