import {type ComponentPropsWithRef, forwardRef, useCallback, useState} from 'react';

import MinusCircle from '@/assets/icons/minus-circle.svg?react';
import PlusCircle from '@/assets/icons/plus-circle.svg?react';

type DisclosureProps = ComponentPropsWithRef<'details'> & {
  label: string;
};

const Disclosure = forwardRef<HTMLDetailsElement, DisclosureProps>(
  ({label, children, ...props}, ref) => {
    // we're using this because `open` css selector doesn't have good browser support
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback(() => setIsOpen(open => !open), []);

    return (
      <>
        <details
          {...props}
          open={isOpen}
          onToggle={onToggle}
          ref={ref}
          className="disclosure-details list-none border-b-2 py-3 first-of-type:mt-2 first-of-type:border-t-2"
        >
          <summary className="flex items-center justify-between text-left font-bold">
            <span className="text-lg">{label}</span>
            {isOpen ? <MinusCircle className="text-lg" /> : <PlusCircle className="text-lg" />}
          </summary>
          {children}
        </details>
      </>
    );
  },
);

export default Disclosure;
