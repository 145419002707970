import {type FC, Fragment} from 'react';
import useTranslation from 'next-translate/useTranslation';

import useTracking from '@/hooks/useTracking';
import {PRIVACY_PATH} from '@/paths';
import {compilePath} from '@/utils';
import redirect from '@/utils/redirect';
import {type TrackerContextType} from '../TrackingProvider';
import Divider from './Divider';

type TranscendButtonProps = {
  as?: 'li' | 'span';
  className?: string;
  hideDivider?: boolean;
};

const handleClick = (tracker: TrackerContextType) => {
  tracker.trackClick('footer-transcend-modal');
  if ((window as any).transcend) {
    (window as any).transcend.showConsentManager({viewState: 'DoNotSellExplainer'});
  } else {
    redirect(compilePath(PRIVACY_PATH));
  }
};

const TranscendButton: FC<TranscendButtonProps> = ({
  as: Tag = 'span',
  className,
  hideDivider = false,
}) => {
  const {t} = useTranslation('common');
  const tracker = useTracking();

  return (
    <Fragment key={'doNotSellMyInfo'}>
      {!hideDivider && <Divider />}
      <Tag>
        <button
          onClick={() => {
            handleClick(tracker);
          }}
          className={`hover:underline hover:decoration-2 hover:underline-offset-4 ${className}`}
          type="button"
        >
          {t(`components.Footer.pathNames.doNotSellMyInfo`)}
        </button>
      </Tag>
    </Fragment>
  );
};

export default TranscendButton;
