import {Icon} from '@ezcater/tapas';
import {
  faLinkedin,
  faSquareFacebook,
  faSquareInstagram,
  faSquareXTwitter,
} from '@fortawesome/free-brands-svg-icons';

import {gtm} from '@/utils';

type SocialLinksProps = {
  className?: string;
};

const trackGTMEvent = (href: string) => {
  gtm.dataLayer({
    event: 'social_profile_click',
    click_destination: href,
  });
};

const SocialLinks: React.FC<SocialLinksProps> = ({className}) => (
  <div
    className={
      className ||
      'flex justify-center gap-3 pt-4 text-[1.4rem] [&_a]:inline-block [&_a]:text-peppercorn-800'
    }
  >
    <a
      href="https://www.linkedin.com/company/ezcater"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="linkedin"
      onClick={() => trackGTMEvent('https://www.linkedin.com/company/ezcater')}
    >
      <Icon icon={faLinkedin} size="medium" className="rounded-md" />
    </a>
    <a
      href="https://www.facebook.com/pages/ezCater/157973357563522"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="facebook"
      onClick={() => trackGTMEvent('https://www.facebook.com/pages/ezCater/157973357563522')}
    >
      <Icon icon={faSquareFacebook} size="medium" />
    </a>
    <a
      href="https://twitter.com/ezcater"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="twitter"
      onClick={() => trackGTMEvent('https://twitter.com/ezcater')}
    >
      <Icon icon={faSquareXTwitter} size="medium" />
    </a>
    <a
      href="https://www.instagram.com/ezcater/"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="instagram"
      onClick={() => trackGTMEvent('https://www.instagram.com/ezcater/')}
    >
      <Icon icon={faSquareInstagram} size="medium" />
    </a>
  </div>
);

export default SocialLinks;
